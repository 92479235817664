<template lang="pug">
	.section_segmenter
		v-container(fluid)
			v-row(justify="center")
				v-col(sm="10" class="pt-0")
					v-stepper( v-model="currentStep" tile class="my-0")
						v-stepper-header
							template( v-for="step in steps")
								v-stepper-step(
									:key="`${step.number}-step`"
									:complete="currentStep > step.number"
									:step="step.number") {{ step.title }}
								v-divider(
									v-if="step.number !== steps.length"
									:key="step.id")
						v-stepper-items
							v-stepper-content( step="1")
								div(class="text-body-2") Selecciona el tema que deseas utilizar
								v-tabs(v-model="tab")
									v-tab( v-for="item in items" :key="item"  ) {{ item }}
									v-tab-item
										modelos-machine-learning( v-on:nextStep="nextStep()")
									v-tab-item
										comportamiento-cliente( v-on:nextStep="nextStep()")
							//-v-stepper-items
							v-stepper-content( step="2")
								div(class="text-body-2") * Selecciona el tema que deseas utilizar
								v-tabs(v-model="tabFilter")
									v-tab( v-for="item in filtrosItems" :key="item"  ) {{ item }}
									v-tab-item
										tipo-cliente( v-on:nextStep="nextStep()" v-on:prevStep="prevStep()")
									v-tab-item
										sociodemograficos( v-on:nextStep="nextStep()" v-on:prevStep="prevStep()")
									v-tab-item
										geograficos( v-on:nextStep="nextStep()" v-on:prevStep="prevStep()")
							//-v-stepper-items
							v-stepper-content( step="3")
								datos-component(v-on:nextStep="nextStep()"  v-on:prevStep="prevStep()")
							//-v-stepper-items
							v-stepper-content( step="4")
								formulario-component( v-on:nextStep="nextStep()"
									:show_alert="show_alert"
									v-on:prevStep="prevStep()"
									:btn_save_loading="btn_save_loading")

</template>

<script>
import { mapGetters } from 'vuex';
// Modelos dinámicos
import ModelosMachineLearning from './components/ModelosMachineLearning'
import ComportamientoCliente from './components/ComportamientoCliente'
import Sociodemograficos from './components/Sociodemograficos'
import Geograficos from './components/Geograficos'
import TipoCliente from './components/TipoCliente'
import DatosComponent from './components/DatosComponent'
import FormularioComponent from './components/FormularioComponent'
// Service
import UseCasesService from '../../../services/UseCasesService'
const sseCasesService = new UseCasesService();

export default {
	name: 'Segmentador',

	components: {
		ModelosMachineLearning,
		ComportamientoCliente,
		Sociodemograficos,
		Geograficos,
		TipoCliente,
		DatosComponent,
		FormularioComponent
	},
	computed: {
		...mapGetters(['step1','step2','step3','step4', 'stepsState']),
	},
	data: () => ({
		tab: 0,
		tabFilter: 0,
		currentStep: 1,
		show_alert: false,
		items: [ 'Modelos de Machine Learning', 'Comportamiento del cliente'],
		filtrosItems: [ 'Tipo de cliente', 'Sociodemográico', 'Geográficos'],
		steps: [
			{ title: 'Temáticos', number: 1 },
			{ title: 'Filtros', number: 2},
			{ title: 'Datos de salida', number: 3 },
			{ title: 'Formulario', number: 4 }
		],
		btn_save_loading: false
	}),
	methods: {
		send_request:function(){
			
			var formData = new FormData();

			if( this.stepsState.step1 ) {
				formData.append("step1", JSON.stringify(this.step1));
			}

			if( this.stepsState.step2 ) {
				formData.append("step2", JSON.stringify(this.step2));
			}
			
			formData.append("step3", JSON.stringify(this.step3));
			formData.append("step4", JSON.stringify(this.step4));

			this.btn_save_loading = true;
			sseCasesService.segmenter(formData)
			.then((response)=>{
				this.show_alert = true;
				this.btn_save_loading = false;
			
				})
			.catch((error)=>{
				this.btn_save_loading = false;
			})

		},
		nextStep () {
			
			if(this.currentStep == this.steps.length ) {
				this.send_request();
				return;
			}

			this.currentStep+=1;
			window.scrollTo(0, 0)
		},
		prevStep () {
			// Verifica que no sea menor que el primer elemento
			if (this.currentStep - 1 <= 0) 
				return
			//- this.currentStep = this.steps[this.currentStep - 2]
			this.currentStep-=1;
			window.scrollTo(0, 0)
		}
	},
	mounted(){
		console.log('counted index')
		this.$store.dispatch('segmenterClear');
	}
}
</script>
<style>
	.custom__conatiner {
		padding: 2em 1em;
	} 
</style>



