<template lang="pug">
	div
		v-card-title.justify-center
			h1.text-center.text-h4.text-md-h4.blue-500--text Datos de Salida
		v-card-text
			v-row(justify="center")
				v-col(sm="6")
					p(class="text-body-2 text-center mt-2 mb-0") ¡Ya casi terminas!
					p(class="text-body-2 text-center mb-0") Aquí deberás elegir la información del cliente que necesitas para detonar tu campaña o proyecto. El formato del archivo que se exportará es .csv
			v-container
				h3.blue-500--text Datos a exportar
				ValidationObserver(ref='observer')
					ValidationProvider(v-slot='{ errors }' name='Información del cliente' rules='required')
						v-row
							v-col(cols='12' md='4')
								v-checkbox(label='id cliente' v-model='step_data.datos_segment' outlined=''  value='numerocliente')
								//- v-checkbox(label='genero' v-model='step_data.datos_segment' outlined=''  value='genero')
							//- v-col(cols='12' md='4')
								v-checkbox(label='Nombre propio' v-model='step_data.datos_segment' outlined=''  value='nombre')
								v-checkbox(label='Apellido Paterno' v-model='step_data.datos_segment' outlined=''  value='apellidopaterno')
								v-checkbox(label='Apellido Materno' v-model='step_data.datos_segment' outlined=''  value='apellidomaterno')
							v-col(cols='12' md='4')
								v-checkbox(label='Correo electrónico' v-model='step_data.datos_segment' outlined=''  value='des_correoelectronico')
								//- v-checkbox(label='Teléfono' v-model='step_data.datos_segment' outlined=''  value='telefono')
							v-col(cols='12' md='12' class="text-center")
								p <b>Nota:</b> Debido al manejo de información sensible, si requieres otros datos personales del cliente, por favor solicítalo directamente al área comentado tu necesidad y  adjuntando la segmentación que creaste. Contacto: rebeca.renedo@coppel.com, yessica.lara@coppel.com 

							v-col(cols='12' md='12' class="text-end")
								label(v-for="error of errors " class="red--text") {{error}}
		
		v-card-actions.px-0.text-end(justify='end')
			v-btn(color='grey' dark tile elevation @click='prevStep')  Anterior 
			v-spacer
			v-btn(color='primary' tile elevation @click='onClickSaveBtn')  Siguiente

</template>

<script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
export default {
	name: "DatosComponent",

	data() {
		return {
			step_data: {
				datos_segment: [] 
			},
			temp_data : {}
		};
	},
	methods: {
		prevStep: function() {
			this.$emit('prevStep');
		},
		onClickSaveBtn() {
			this.$refs.observer.validate().then((res) => {
				if (res) {
					const val = this.step_data.datos_segment;
					if(Array.isArray(val) && val.length>0){
						this.temp_data["datos_segment"] = val;
						this.$store.dispatch('step3_data',this.temp_data);
					}
					this.$emit('nextStep');
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped></style>