<template lang="pug">
	v-card( outline tile)

		v-row(justify="center")
			v-col( cols="12" sm="8")
				h6(class="text-body-2  text-center mt-5") Podrás realizar segmentaciones o recomendaciones de y para clientes Coppel utilizando modelos analiticos con aprendizaje automático que permite aprender de los datos, identificar patrones y tomar las mejores decisiones para detonar una comunicación exitosa.
			
	
		v-row(justify="center")
			v-col( cols="12")
					h2( class="text-h6 text-center font-weight-bold blue-500--text mt-5") Modelos de machine learning
			v-col( cols="12" class="d-flex justify-center")
				v-checkbox(
					class="mr-2"
					v-for="n in opciones"
					v-model="modelType"
					:key="n"
					:label="`${n}`"
					:value="n")
				
		ModelosSegmentacionClientes( v-if="modelType === 'Modelos de segmentación de clientes' " v-on:nextStep="onNextStep()")
		
		v-card-actions( v-if="!modelType" )
			v-spacer
			v-btn( color="primary" @click="onNextStep()"  tile elevation="0") Siguiente
</template>
<script>

import ModelosSegmentacionClientes from './ModelosSegmentacionClientes'

export default {
	name: 'ModelosComponent',
	components: {
		ModelosSegmentacionClientes
	},
	data() {
		return {
			errors: '',
			opciones: ['Modelos de segmentación de clientes', 'Modelos de recomendación de productos'],
			modelType: 'Modelos de segmentación de clientes',
		}
	},
	methods: {	
		onNextStep() {
			this.$emit('nextStep');
		}
	}
}
</script>