<template lang="pug">
    ValidationObserver(ref='observer')
        v-card-text()
            v-row( justify="center" align="center")
                v-col(cols='10' class="my-5" )
                        v-divider
                v-col( sm="12")
                    h1( class="text-center text-h6 blue-500--text") Comportamiento de abonos
                    //-p( class="text-center my-3") Elige el modelo de segmentación de clientes que mas se ajuste a tus necesidades.
                

            v-row
                v-col(cols='12' sm='6' md='4')
                    p( class="text-title font-weight-bold blue-500--text mt-5") Último movimiento de Abono
                    v-menu(
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto")
                        template(v-slot:activator='{ on, attrs }')
                            //- ValidationProvider(v-slot='{ errors }' name='Fecha del ultimo movimiento de Abono' rules='required')
                            v-text-field(
                                v-model="step_data.date_abono"
                                label="Selecciona una fecha o intervalo de fechas "
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on")
                        v-date-picker(
                            v-model="step_data.date_abono"
                            range
                            @change="ultimoAbonoDatePicker"
                            @input="menu = false"
                            min="2018-01-27"
                            :max="max_date")
                v-col(cols='12' md='4')
                    //- ValidationProvider(v-slot='{ errors }' name='canal abono' rules='required')
                    p( class="text-title font-weight-bold blue-500--text mt-5") Canal de abono
                    v-select( 
                            :items="chanels" 
                            label="Selecciona una o varias opciones"
                            v-model='step_data.des_canal'
                            multiple chips)

                v-col(cols='12' md='4')
                    //- ValidationProvider(v-slot='{ errors }' name='canal abono' rules='required')
                    p( class="text-title font-weight-bold blue-500--text mt-5") Abono a terceros
                    v-select( 
                            :items="abonoTerceros" 
                            label="Selecciona una o opción"
                            v-model='step_data.email_tercero'
                            chips)
                
                v-col(cols='12' md='4')
                    //- ValidationProvider(v-slot='{ errors }' name='Importe Abonado' rules='required')
                    p( class="text-title font-weight-bold blue-500--text mt-5") Importe abonado (MXN)
                    label(class="text-caption") Selecciona el intervalo del importe que abonó el cliente.
                    p(class="text-caption text-end mt-1") Rango: {{step_data.importe[0]}} - {{step_data.importe[1]}}
                    v-range-slider(
                        step='1'
                        min='0' max='108820'
                        v-model="step_data.importe"
                        class="mt-10")

        v-card-actions
            v-spacer
            v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
    
</template>

<script>
import { arrPlainEquals } from '../../../../utils/helpers'

export default {
    name: 'ComportamientoAbonos',
    data() {
        return {
            abonoTerceros:[
                {text:"Seleccione una opción", value:''},
                {text:"Sí", value:'yes'},
                {text:"No", value:'no'},
                {text:"Todos", value:'all'},
            ],
            chanels:[
                {text:"Coppel.com", value:"APP COPPEL"},
                {text:"App coppel", value:"COPPEL.COM"},
                {text:"Whatsapp", value:"WHATSAPP"},
                {text:"Sin identificar", value:"unidentified"},
            ],
            step_data :{
                segment: 'comportamientoabonos',
                date_abono: [], 
                des_canal: [],
                email_tercero: '',
                importe: [0,0]
            },
            temp_data : {},
            max_date: new Date().toISOString().substr(0, 10),
        }
    },
    methods: {
         handleNextStep:  function() {
			this.$refs.observer.validate().then( async res=> {
				if(res){
					this.$store.dispatch('update_step_state', { step1: this.hasStepSomeData() })
					await this.process_fields()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('nextStep');
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
        process_fields:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				for(const field in this.step_data){
                    const val = this.step_data[field];
                    if(Array.isArray(val) && val.length>0 && !arrPlainEquals( val, [0,0] ) ){
                        this.temp_data[field] = val;
                    }
                    if ( typeof val == 'string' && val !== '' ) {
                        this.temp_data[field] = val;
                    }
                }
				resolve();
			})
		},
        hasStepSomeData() {
            var flag = false;
            for(const field in this.step_data){
                const itemField = this.step_data[field];
                if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] )){
                    flag = true;
                    break;
                }
            }
            if(this.step_data.email_tercero !== '' || flag) {
                return true;
            }
            return false
		},
        // si la fecha 1 es mayor a la fecha 2 en el rango se invierten los valores
		evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
        ultimoAbonoDatePicker: function(range) {
			this.step_data.date_abono = this.evalRangeDatePicker(range);
		},
    }
}
</script>