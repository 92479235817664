<template lang="pug">
    ValidationObserver(ref='observer')
        //-v-row( justify="center" align="center")
            v-col( sm="12")
                h4( class="text-center text-h7 blue-500--text") Compras a detalle
        v-row 
            v-col 
                v-tooltip( top class="d-inline")
                    template(v-slot:activator='{ on, attrs }')
                        v-btn(icon color='orange' v-bind='attrs' v-on='on')
                            v-icon mdi-alert-circle
                    p( class="text-caption text-justify") 
                        | Sin soporte para envío de audiencias a Google Ads
        v-row
            v-col(cols='12' sm='6' md='4')
                v-menu(
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto")
                    template(v-slot:activator='{ on, attrs }')
                        p( class="text-title font-weight-bold blue-500--text mt-5") Fecha de orden de compra
                        v-text-field(
                            v-model="step_data.fec_fechaorden"
                            label="Selecciona una fecha o intervalo de fechas"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on")
                    v-date-picker(
                        v-model="step_data.fec_fechaorden"
                        @input="menu = false"
                        range
                        @change="fecOrdenCompraDatePicker"
                        min="2011-12-28"
                        :max="max_date")

            v-col(cols='12' sm='6' md='4')
                v-menu(
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto")
                    template(v-slot:activator='{ on, attrs }')
                        p( class="text-title font-weight-bold blue-500--text mt-5") Fecha de facturación de compra
                        v-text-field(
                            v-model="step_data.fec_FechaFacturacion"
                            label="Selecciona una fecha o intervalo de fechas"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on")
                    v-date-picker(
                        v-model="step_data.fec_FechaFacturacion"
                        @input="menu = false"
                        @change="fecFacturacionCompraDatePicker"
                        range
                        min="2013-01-01"
                        :max="max_date")

        v-row

            v-col(cols='12' md='4')
                //- ValidationProvider(v-slot='{ errors }' name='canal abono' rules='required')
                p( class="text-title font-weight-bold blue-500--text mt-5") Canal de venta
                v-select( 
                        :items="canalVentaItems" 
                        label="Selecciona una o varias opciones"
                        v-model='step_data.des_canalventa'
                        multiple chips)
        
            v-col(cols='12' md='4')
                p( class="text-title font-weight-bold blue-500--text mt-5") Tipo de compra
                v-select( 
                        :items="tipoCompraItems" 
                        label="Selecciona una o varias opciones"
                        v-model='step_data.des_tipocompra'
                        multiple chips)
            
            v-col(cols='12' md='4')
                p( class="text-title font-weight-bold blue-500--text mt-5") Tipo de pago inicial
                v-select( 
                        :items="pagoInicialItems" 
                        label="Selecciona una o varias opciones"
                        v-model='step_data.des_pagoinicial'
                        multiple chips)
            
            v-col(cols='12' md='4')
                p( class="text-title font-weight-bold blue-500--text mt-5") Dispositivo utilizado
                v-select( 
                        :items="dispositivoUtilizadoItems" 
                        label="Selecciona una o varias opciones"
                        v-model='step_data.des_Dispositivo'
                        multiple chips)
        v-row
            v-col(cols='12' md='4')
                p( class="text-title font-weight-bold blue-500--text mt-5") Área de productos
                v-select( 
                        :items="areaItems" 
                        label="Selecciona una o varias opciones"
                        v-model='step_data.des_Area'
                        multiple chips)

            v-col(cols='12' md='4') 
                p( class="text-title font-weight-bold blue-500--text mt-5") Categorías
                v-select( 
                        :items="categoryItems"
                        :loading="loadingInputsCategoriaSubcatDepClase"
                        label="Selecciona una o varias opciones"
                        v-model='step_data.des_categoria'
                        multiple chips)

            v-col(cols='12' md='4') 
                p( class="text-title font-weight-bold blue-500--text mt-5") Subcategorías
                v-select( 
                        :items="subcategoryItems"
                        :loading="loadingInputsCategoriaSubcatDepClase"
                        label="Selecciona una o varias opciones"
                        v-model='step_data.des_subcategoria'
                        multiple chips)
        v-row 
            v-col(cols='12' md='4') 
                p( class="text-title font-weight-bold blue-500--text mt-5") Departamento
                v-select( 
                        :items="depProductsItems" 
                        :loading="loadingInputsCategoriaSubcatDepClase"
                        label="Selecciona una o varias opciones"
                        v-model='step_data.des_departamento'
                        @blur="onBlurSelectDep"
                        multiple chips)
        

            v-col(cols='12' md='4') 
                p( class="text-title font-weight-bold blue-500--text mt-5") Clase
                label(class="text-caption") Busca y elige una o varias clases
                v-autocomplete(
                    append-icon=""
                    append-outer-icon="mdi-magnify"
                    :loading="loadingInputsCategoriaSubcatDepClase"
                    v-model="step_data.des_clase"
                    :items="clasesItems"
                    chips
                    label="Busca y elige una o varias clases"
                    small-chips
                    clearable
                    multiple
                )
        v-row 

            v-col(cols='12' md='4') 
                p( class="text-title font-weight-bold blue-500--text mt-5") Marca
                label(class="text-caption") Busca y elige una o varias marcas
                v-autocomplete(
                    append-icon=""
                    append-outer-icon="mdi-magnify"
                    :loading="loadingInputMarcas"
                    v-model="step_data.des_marca"
                    :items="marcasItems"
                    chips
                    label="Busca y elige una o varias marcas"
                    small-chips
                    clearable
                    multiple
                )

            v-col(cols='12' md='4') 
                p( class="text-title font-weight-bold blue-500--text mt-5") Descripción de artículo
                label(class="text-caption") Busca y elige una o varias descripciones
                v-autocomplete(
                    append-icon=""
                    append-outer-icon="mdi-magnify"
                    :loading="loadingInputArticulosDesc"
                    v-model="step_data.des_articulo"
                    :items="desArticulosItems"
                    chips
                    label="Busca y elige una o varias descripciones"
                    small-chips
                    clearable
                    multiple
                )

            v-col(cols='12' md='4') 
                p( class="text-title font-weight-bold blue-500--text mt-5") Nombre del artículo
                label(class="text-caption") Busca y elige uno o varios nombres
                v-autocomplete(
                    append-icon=""
                    append-outer-icon="mdi-magnify"
                    :loading="loadingInputArticulos"
                    v-model="step_data.nom_articulo"
                    :items="nomArticulosItems"
                    chips
                    label="Busca y elige uno o varios nombres"
                    small-chips
                    clearable
                    multiple
                )
        
        v-row

            v-col(cols='12' md='4')
                    p( class="text-title font-weight-bold blue-500--text mt-5") Cantidad de artículos por orden
                    label(class="text-caption") Selecciona el intervalo de articulos
                    p(class="text-caption text-end mt-1") Rango: {{step_data.cantidad_articulos[0]}} - {{step_data.cantidad_articulos[1]}}
                    v-range-slider(
                        step='1'
                        min='0' max='200'
                        v-model="step_data.cantidad_articulos"
                        class="mt-10")
					
            v-col(cols='12' md='4')
                p( class="text-title font-weight-bold blue-500--text mt-5") Precio contado por artículo (MXN)
                label(class="text-caption") Selecciona el intervalo de precio contado
                p(class="text-caption text-end mt-1") Rango: {{step_data.precio_articulo_contado[0]}} - {{step_data.precio_articulo_contado[1]}}
                v-range-slider(
                    step='1'
                    min='0' max='109999'
                    v-model="step_data.precio_articulo_contado"
                    class="mt-10")

            v-col(cols='12' md='4')
                p( class="text-title font-weight-bold blue-500--text mt-5") Precio crédito por artículo (MXN)
                label(class="text-caption") Selecciona el intervalo del precio credito
                p(class="text-caption text-end mt-1") Rango: {{step_data.precio_articulo_credito[0]}} - {{step_data.precio_articulo_credito[1]}}
                v-range-slider(
                    step='1'
                    min='0' max='189198'
                    v-model="step_data.precio_articulo_credito"
                    class="mt-10")
        
        v-card-actions
            v-spacer
            v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente

    
</template>


<script>

import { arrPlainEquals } from '../../../../utils/helpers'

import  InputItemsService from '@/services/InputItemsService';
const inputItemsService = new InputItemsService();

export default {
	name: 'ComprasDetalle',
    data() {
        return {

            canalVentaItems: [
                {text:"Coppel.com",value:"COPPEL.COM"},
                {text:"App Coppel",value:"APP COPPEL"},
                {text:"Coppel.com en tienda",value:"COPPEL.COM EN TIENDA"},
                {text:"Mercado Libre",value:"MERCADO LIBRE"},
            ],
            tipoCompraItems: ['Crédito Coppel', 'Venta Empleado', 'Contado'], 
	    	pagoInicialItems: [ 'Con pago inicial', 'Sin pago inicial', 'Contado' ],
            dispositivoUtilizadoItems: [
                {text:"Android",value:"ANDROID"},
                {text:"Desktop",value:"DESKTOP"},
                {text:"IOS",value:"IOS"},
                {text:"Mobile",value:"MOBILE"},
                {text:"Tablet",value:"TABLET"},
                {text:"Sin dato",value:"unidentified"},
            ],
            areaItems: [
                {text:"Muebles",value:"Muebles"},
                {text:"Ropa",value:"Ropa"},
                {text:"Sin dato",value:"unidentified"},
            ],
            categoryItems: [],

            subcategoryItems: [],

            depProductsItems: [],

            //loading
            loadingInputClase: false,
			//loadingInputFamilia: false,
			loadingInputMarcas: false,
			loadingInputArticulosDesc: false,
			loadingInputArticulos: false,
            loadingInputsCategoriaSubcatDepClase: false,

            familyItems: [],
            clasesItems: [],
            marcasItems: [],
            desArticulosItems: [],
            nomArticulosItems: [],

            step_data :{
                segment: 'comprasdetalle',

                fec_FechaFacturacion: [],
                fec_fechaorden: [],
                des_canalventa: [],
                des_Dispositivo: [],
                des_tipocompra: [],
                des_pagoinicial: [],
                des_Area: [],
                des_categoria: [],
                des_subcategoria: [],
                des_departamento: [],
                //des_familia: [],
                des_clase: [],
                des_marca: [],
                des_articulo: [],
                nom_articulo: [],

                cantidad_articulos: [0,0],
                precio_articulo_credito: [0,0],
                precio_articulo_contado: [0,0],

            },
            temp_data : {},
            max_date: new Date().toISOString().substr(0, 10),
        }
    },
    watch:{
        // fill select Categoría
        "step_data.des_Area": function(areas) {
            // reset
            this.step_data.des_categoria = [];
            this.step_data.des_departamento = [];
            // Filter categories by area
            if(areas.length > 0) {
                this.categoryItems = this.removeDuplicateItems(
                    this.area_dep_cat_subcat_group_array
                        .filter(item =>{
                            return areas.includes(item.area);
                        })
                        .map(item => item.categoria) 
                ).sort();

                this.depProductsItems = this.removeDuplicateItems(
                    this.area_dep_cat_subcat_group_array
                        .filter(item => {
                            return areas.includes(item.area);
                        })
                        .map(item => item.departamento )
                ).sort();
            } else {
                this.categoryItems = this.removeDuplicateItems(  this.area_dep_cat_subcat_group_array.map(item => item.categoria) ).sort()
                this.depProductsItems = this.removeDuplicateItems(  this.area_dep_cat_subcat_group_array.map(item => item.departamento) ).sort()
            }
		},
        // fill select Subcategoría de productos
        "step_data.des_categoria": function(categories) {
            // reset
            this.step_data.des_subcategoria = [];
            // Filter subcategory by category
            if(categories.length > 0) {
                this.subcategoryItems = this.removeDuplicateItems(
                    this.area_dep_cat_subcat_group_array
                        .filter(item =>{
                            return categories.includes( item.categoria)
                        })
                        .map(item => item.subcategoria )
                ).sort();
            } else {
                this.subcategoryItems = this.removeDuplicateItems(  this.area_dep_cat_subcat_group_array.map(item => item.subcategoria) ).sort()
            }
		},
        "step_data.des_clase": function(clases) {
            // limpiar Familia
            this.familyItems = [];
            this.step_data.des_Familia = [];
        }
    },
    methods: {
        init() {
			//this.loadingInputClase = true;
			this.loadingInputFamilia = true;
			this.loadingInputMarcas = true;
			this.loadingInputArticulosDesc = true;
			this.loadingInputArticulos = true;
            this.loadingInputsCategoriaSubcatDepClase = true;


            // inputs departamento, categoria, subcategoria
            inputItemsService.fetchAreaDepaCatSubcatClaseJson()
            .then(response => {
                this.area_dep_cat_subcat_group_array = response.data.data;
				this.clasesItems = this.removeDuplicateItems(  this.area_dep_cat_subcat_group_array.map(item => item.clase) ).sort()
                this.categoryItems = this.removeDuplicateItems(  this.area_dep_cat_subcat_group_array.map(item => item.categoria) ).sort()
                this.subcategoryItems = this.removeDuplicateItems(  this.area_dep_cat_subcat_group_array.map(item => item.subcategoria) ).sort()
                this.depProductsItems = this.removeDuplicateItems(  this.area_dep_cat_subcat_group_array.map(item => item.departamento) ).sort()
                this.des_categoria_rfm = this.categoryItems;
			})
			.catch(errors => {
				console.log(errors)
			})
			.finally(()=>{
                this.loadingInputsCategoriaSubcatDepClase = false;
			})


            /*
			inputItemsService.jsonArticulosClase()
			.then(response => {
				this.clasesItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
				this.loadingInputClase = false;
			})*/

            inputItemsService.jsonArticulosMarca()
			.then(response => {
				this.marcasItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputMarcas = false;
			})

            inputItemsService.jsonArticulosDescripcion()
			.then(response => {
				this.desArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputArticulosDesc = false;
			})

            inputItemsService.jsonArticulosNombre()
			.then(response => {
				this.nomArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputArticulos = false;
			})
		},
        removeDuplicateItems(arr) {
            return [... new Set(arr)]
        },
        onBlurSelectDep() {
            //this.loadingInputClase = true;
            this.clasesItems = [];
            this.step_data.des_clase = [];
            let departaments = this.step_data.des_departamento;
            this.clasesItems = this.removeDuplicateItems(  
                this.area_dep_cat_subcat_group_array
                    .filter(item => departaments.includes(item.departamento)) 
                    .map(item => item.clase)
            ).sort();

            /*
            inputItemsService.jsonArticulosClase(departaments)
            .then(response => {
                this.clasesItems = response.data.data;
            })
            .catch(errors => {
                console.log(errors)
            })
            .then(()=>{
                this.loadingInputClase = false;
            })*/
        
        },
        handleNextStep:  function() {
			this.$refs.observer.validate().then( async res=> {
				//- if the form is valid
                
				if(res){
					this.$store.dispatch('update_step_state', { step1: this.hasStepSomeData() })
					await this.filter_form_fields()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('nextStep');
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
        filter_form_fields:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				this.temp_data.segment = this.step_data.segment;
				for(const field in this.step_data){
                    const val = this.step_data[field];
                    if(Array.isArray(val) && val.length>0 &&  !arrPlainEquals( val, [0,0] )){
                        this.temp_data[field] = val;
                    }
                }
				resolve();
			})
		},
        hasStepSomeData() {
            var flag = false;
            for(const field in this.step_data){
                const itemField = this.step_data[field];
                if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] ) ){
                    flag = true;
                    break;
                }
            }
            return flag;
		},
        evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
        fecOrdenCompraDatePicker: function(range) {
			this.step_data.fec_fechaorden = this.evalRangeDatePicker(range);
            //
            this.step_data.fec_FechaFacturacion = [];
		},
        fecFacturacionCompraDatePicker: function(range) {
			this.step_data.fec_FechaFacturacion = this.evalRangeDatePicker(range);
            //
            this.step_data.fec_fechaorden = [];

		},
    },
    created() {
        this.init();
    }
    
}
</script>