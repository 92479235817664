import BackendAuthService from "./BackendAuthService";

export default class UseCasesService extends BackendAuthService {

	constructor() {
		super();
	}

	fetchAreaDepaCatSubcatClaseJson(){
		// json con area, departamento, categoria, subcaegoria y familia
		let url = 'interfaz/v1/inputs/area-clasifications';
		return this.httpCall(url,{
            method: 'GET',
		});
	}

	getStateWithCities() {
		return this.httpCall('interfaz/api/v1/states-with-cities',{
            method: 'GET',
		});
	}

	// Casos de uso 
	
	// en deshuso
	jsonArticulosClase(departments=undefined){
		let url = 'api/v1/usecases/articulos-clase';
		if (departments) {	
			let depStr = Array.isArray(departments) ? departments.join('|') : departments;
			url = 'api/v1/usecases/articulos-clase?departments='+depStr;
		}
		return this.httpCall(url,{
            method: 'GET',
		});
	}

	jsonArticulosFamilia(clase=undefined){
		let url = 'api/v1/usecases/articulos-familia';
		if (clase) {	
			let claseStr = Array.isArray(clase) ? clase.join('|') : clase;
			url = 'api/v1/usecases/articulos-familia?clase='+claseStr;
		}
		return this.httpCall(url,{
            method: 'GET',
		});
	}

	jsonArticulosMarca(){
		return this.httpCall('api/v1/usecases/articulos-marca',{
            method: 'GET',
		});
	}

	jsonArticulosNombre(){
		return this.httpCall('api/v1/usecases/articulos-nombre',{
            method: 'GET',
		});
	}

	jsonArticulosDescripcion(){
		return this.httpCall('api/v1/usecases/articulos-descripcion',{
            method: 'GET',
		});
	}

}

