<template lang="pug">
	v-card(outline tile)
		v-row(justify="center")
			v-col( cols="12" sm="12" class="ml-5 text-center" )
				p(class="text-body-2 text-center mt-5 mb-0")  En esta sección puedes filtrar las características del cliente Coppel.
					ValidationObserver(ref='observer')
		ValidationObserver(ref='observer')
			v-card-text()
				v-row(justify="center" color="primary")
					v-col(cols='10' class="my-5" )
						v-divider 
				v-row
					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Tipo de Cliente
						v-select( 
							:items="client" 
							label="Selecciona una o varias opciones"
							v-model='step_data.des_TipoCliente'
							multiple chips)
					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Puntualidad del cliente
						v-select( 
							:items="puntuality" 
							label="Selecciona una o varias opciones"
							v-model='step_data.puntualidadcliente'
							multiple chips)
					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='es cliente' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Cliente Digital
						v-select( 
							:items="isClient" 
							label="Selecciona una opción"
							v-model='step_data.flag_ClienteDigital')
					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='Antiguedad del cliente' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Antigüedad del cliente en Coppel
						label(class="text-caption") Elige el periodo de antigüedad como Cliente Coppel. (Calculado en años)
						p(class="text-caption text-end") Rango: {{step_data.old[0]}} - {{step_data.old[1]}}
						v-range-slider(
							step='1'
							min='0' max='15'
							v-model="step_data.old"
							class="mt-2")
				v-row
					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='es cliente' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Origen del registro del cliente
						v-select( 
							:items="origenRegistroCliente" 
							label="Selecciona una o varias opciones"
							v-model='step_data.des_Origen' 
							multiple chips)

					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='es cliente' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Registro de correo
						v-select( 
							:items="registroCorreo" 
							label="Selecciona una o varias opciones"
							v-model='step_data.flag_registro'
							multiple chips)

					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='es cliente' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Correo activo
						v-select( 
							:items="correoActivo" 
							label="Selecciona una o varias opciones"
							v-model='step_data.flag_registroactivo'
							multiple chips)
					
		v-card-actions.px-4.text-end(justify='end')
			v-btn(color='grey' dark tile elevation @click='prevStep')  Anterior 
			v-spacer
			v-btn.ml-auto(color='primary' @click='handleNextStep')  Siguiente
		v-dialog( v-model="dialog" max-width="290" )
			v-card
				v-card-title( class="headline" ) ¡Aviso!
				v-card-text 
					p( class="black-500--text ") 
						| Para continuar es necesario haber seleccionado al menos un campo del paso uno o del paso dos.
				v-card-actions
					v-spacer
					v-btn( color="blue darken-1" text @click="dialog = false") Cerrar
</template>

<script>

import { arrPlainEquals } from '../../../../utils/helpers'
import { mapGetters } from 'vuex';


export default {
	name: 'ComportamientoComponent',
	computed: {
		...mapGetters(['canIGoToStep3'])
	},
	data: () => ({
		dialog: false,
		puntuality:[
			{text:"Cliente A",value:"A"},
			{text:"Cliente B",value:"B"},
			{text:"Cliente C",value:"C"},
			{text:"Cliente D",value:"D"},
			{text:"Cliente nuevo",value:"N"},
			{text:"Cliente moroso",value:"Z"},
			//{text:"Sin Dato/No incluir",value:"unidentified"}, // si funciona
		],
		client:[
			{text:"Contado",value:"CLIENTE CONTADO"},
			{text:"Credito Titular",value:"CLIENTE CREDITO TITULAR"},
			{text:"Credito Adicional",value:"CLIENTE CREDITO ADICIONAL"},
			{text:"Credito Especial",value:"CLIENTE CREDITO ESPECIAL"},
			{text:"Empleado Titular",value:"EMPLEADO TITULAR"},
			{text:"Empleado Adicional",value:"EMPLEADO ADICIONAL"},
		],
		isClient:[
			{text:"Selecciona una opción", value: -1},
			{text:"Si", value:1},
			{text:"No", value:0},
			{text:"Todos", value: 2},
		],
		origenRegistroCliente: [
			'App Coppel',
			'Bancoppel',
			'CAT',
			'Cobranzas',
			'Coppel.com',
			'Coppel.com en tienda',
			'Facebook',
			'Personal',
			'Registro empleado',
			'Sin origen',
			'Staff',
			'Tienda',
			'Wifi tiendas',
		],
		registroCorreo:[
			{text:"Si",value: 1},
			{text:"No",value: 0},
		],
		correoActivo: [
			{text: "Si", value: 1},
			{text: "No", value: 0},
			{text:"Sin Dato",value:"unidentified"},

		],

		step_data :{
			tab: 'tipocliente',
			puntualidadcliente:[],
			des_TipoCliente:[],
			flag_ClienteDigital: -1,
			old: [0,0],
			des_Origen: [],
			flag_registro: [],
			flag_registroactivo: []
		},
		temp_data : {}
	
	}),

	methods:{
		prevStep: function() {
			this.$emit('prevStep');
		},
		handleNextStep() {
			this.$refs.observer.validate().then( async res=> {
				//- if the form is valid
				if(res){
					this.$store.dispatch('update_step_state', { step2: this.hasStepSomeData() })
					await this.filter_form_fields()
					.then(resolve=>{
						this.$store.dispatch('step2_data',this.temp_data);
						if(this.canIGoToStep3) {
							this.$emit('nextStep');
						} else {
							this.dialog = true;
						}
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
		filter_form_fields(){
			this.temp_data = {}
			return  new Promise((resolve, reject)=>{
				
				this.temp_data.tab = this.step_data.tab;
				for(const field in this.step_data){
                    const val = this.step_data[field];
                    if(Array.isArray(val) && val.length>0 && !arrPlainEquals( val, [0,0] ) ){
                        this.temp_data[field] = val;
                    }
                }
				if ( this.step_data.flag_ClienteDigital != -1 ) {
					this.temp_data['flag_ClienteDigital'] = this.step_data.flag_ClienteDigital;
				}
				resolve();
			})
		},
		hasStepSomeData() {
			var flag = false;
            for(const field in this.step_data){
                const itemField = this.step_data[field];
                if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] )){
                    flag = true;
                    break;
                }
            }
            if(this.step_data.flag_ClienteDigital != -1 || flag) {
                return true;
            }
            return false
		}
	}
	
}
</script>