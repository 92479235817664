<template lang="pug">
    ValidationObserver(ref='observer')
        //-v-row( justify="center" align="center")
            v-col( sm="12")
                h4( class="text-center text-h7 blue-500--text") Compras generales
        v-row
            v-col(cols='12' md='4')
                p( class="text-title font-weight-bold blue-500--text mt-5") Meses de la últimade actividad en Coppel
                label(class="text-caption") Elige el periodo de la última actividad del cliente en Coppel. (Calculado en meses)
                p(class="text-caption text-end") Rango: {{step_data.activity[0]}} - {{step_data.activity[1]}}
                v-range-slider(
                        step='1'
                        min='0' max='24'
                        v-model="step_data.activity"
                        class="mt-2")
        
        v-row
            v-col(cols='12' sm='6' md='4')
                v-menu(
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto")
                    template(v-slot:activator='{ on, attrs }')
                        //- ValidationProvider(v-slot='{ errors }' name='Fecha de ultima actividad en ropa' rules='required')
                        p( class="text-title font-weight-bold blue-500--text mt-5") Última actividad en área ropa
                        v-text-field(
                            v-model="step_data.date_ropa"
                            label="Selecciona una fecha o intervalo de fechas"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on")
                    v-date-picker(
                        v-model="step_data.date_ropa"
                        @input="menu = false"
                        @change="areaRopaDatePicker"
                        range
                        min="2014-01-01"
                        :max="max_date")
            v-col(cols='12' sm='6' md='4')
                v-menu(
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto")
                    template(v-slot:activator='{ on, attrs }')
                        //- ValidationProvider(v-slot='{ errors }' name='Fecha de ultima actividad en Muebles' rules='required')
                        p( class="text-title font-weight-bold blue-500--text mt-5") Última actividad en área muebles
                        v-text-field(
                            v-model="step_data.date_muebles"
                            label="Selecciona una fecha o intervalo de fechas"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on")
                    v-date-picker(
                        v-model="step_data.date_muebles"
                        @input="menu = false"
                        range
                        @change="areaMueblesDatePicker"
                        min="2014-01-01"
                        :max="max_date")
            v-col(cols='12' sm='6' md='4')
                v-menu(
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto")
                    template(v-slot:activator='{ on, attrs }')
                        //- ValidationProvider(v-slot='{ errors }' name='Fecha de ultima actividad en tiempo Aire' rules='required')
                        p( class="text-title font-weight-bold blue-500--text mt-5") Última actividad en Tiempo Aire
                        v-text-field(
                            v-model="step_data.date_tiempoaire"
                            label="Selecciona una fecha o intervalo de fechas "
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on")
                    v-date-picker(
                        v-model="step_data.date_tiempoaire"
                        @change="tiempoAireDatePicker"
                        @input="menu = false"
                        range
                        min="2020-04-05"
                        :max="max_date")
        v-card-actions
            v-spacer
            v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
           
    
</template>

<script>
import { arrPlainEquals } from '../../../../utils/helpers'

export default {
    name: 'ComprasGenerales',
    data() {
        return {
            step_data :{
                //type_model: '', //
                segment: 'comprasgenerales',
                activity: [0,0],
                date_ropa: [],
                date_muebles: [],
                date_tiempoaire: [],
            },
            temp_data : {},
            max_date: new Date().toISOString().substr(0, 10),
        }
    },
    methods: {
        handleNextStep:  function() {
			this.$refs.observer.validate().then( async res=> {      
				if(res){
					this.$store.dispatch('update_step_state', { step1: this.hasStepSomeData() })
					await this.process_fields()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('nextStep');
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
        process_fields:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				this.temp_data.segment = this.step_data.segment;
                for(const field in this.step_data){
                    const itemField = this.step_data[field];
                    if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] )){
                        this.temp_data[field] = itemField;
                    }
                }
				resolve();
			})
		},
        hasStepSomeData() {
            var flag = false;
            for(const field in this.step_data){
                const itemField = this.step_data[field];
                if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] )){
                    flag = true;
                    break;
                }
            }
            return flag;
		},
        evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
        areaRopaDatePicker: function(range) {
			this.step_data.date_ropa = this.evalRangeDatePicker(range);
		},
		areaMueblesDatePicker: function(range) {
			this.step_data.date_muebles = this.evalRangeDatePicker(range);
		},
		tiempoAireDatePicker: function(range) {
			this.step_data.date_tiempoaire = this.evalRangeDatePicker(range);
		},
    }
    
}
</script>