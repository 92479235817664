var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('p',{staticClass:"text-title font-weight-bold blue-500--text mt-5"},[_vm._v("Meses de la últimade actividad en Coppel")]),_c('label',{staticClass:"text-caption"},[_vm._v("Elige el periodo de la última actividad del cliente en Coppel. (Calculado en meses)")]),_c('p',{staticClass:"text-caption text-end"},[_vm._v("Rango: "+_vm._s(_vm.step_data.activity[0])+" - "+_vm._s(_vm.step_data.activity[1]))]),_c('v-range-slider',{staticClass:"mt-2",attrs:{"step":"1","min":"0","max":"24"},model:{value:(_vm.step_data.activity),callback:function ($$v) {_vm.$set(_vm.step_data, "activity", $$v)},expression:"step_data.activity"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',{staticClass:"text-title font-weight-bold blue-500--text mt-5"},[_vm._v("Última actividad en área ropa")]),_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Selecciona una fecha o intervalo de fechas","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.step_data.date_ropa),callback:function ($$v) {_vm.$set(_vm.step_data, "date_ropa", $$v)},expression:"step_data.date_ropa"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":"","min":"2014-01-01","max":_vm.max_date},on:{"input":function($event){_vm.menu = false},"change":_vm.areaRopaDatePicker},model:{value:(_vm.step_data.date_ropa),callback:function ($$v) {_vm.$set(_vm.step_data, "date_ropa", $$v)},expression:"step_data.date_ropa"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',{staticClass:"text-title font-weight-bold blue-500--text mt-5"},[_vm._v("Última actividad en área muebles")]),_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Selecciona una fecha o intervalo de fechas","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.step_data.date_muebles),callback:function ($$v) {_vm.$set(_vm.step_data, "date_muebles", $$v)},expression:"step_data.date_muebles"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":"","min":"2014-01-01","max":_vm.max_date},on:{"input":function($event){_vm.menu = false},"change":_vm.areaMueblesDatePicker},model:{value:(_vm.step_data.date_muebles),callback:function ($$v) {_vm.$set(_vm.step_data, "date_muebles", $$v)},expression:"step_data.date_muebles"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',{staticClass:"text-title font-weight-bold blue-500--text mt-5"},[_vm._v("Última actividad en Tiempo Aire")]),_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Selecciona una fecha o intervalo de fechas ","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.step_data.date_tiempoaire),callback:function ($$v) {_vm.$set(_vm.step_data, "date_tiempoaire", $$v)},expression:"step_data.date_tiempoaire"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"range":"","min":"2020-04-05","max":_vm.max_date},on:{"change":_vm.tiempoAireDatePicker,"input":function($event){_vm.menu = false}},model:{value:(_vm.step_data.date_tiempoaire),callback:function ($$v) {_vm.$set(_vm.step_data, "date_tiempoaire", $$v)},expression:"step_data.date_tiempoaire"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","tile":"","elevation":"0"},on:{"click":_vm.handleNextStep}},[_vm._v("Siguiente")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }