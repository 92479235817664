import BackendAuthService from "./BackendAuthService";

export default class SegmenterService extends BackendAuthService {

	constructor() {
		super();
	}

	// recommender(data){
	// 	return this.httpCall('recommender/download',{
	// 		method: 'POST',
	// 		data
	// 	});
	// }
	// employees(){
	// 	return this.httpCall('recommender/data-filters',{
	// 		method: 'POST',
	// 	});
	// }

	segmenter(data){
		return this.httpCall('interfaz/segmenter',{
            method: 'POST',
            data
		});
	}
	geographic(){
		return this.httpCall('interfaz/segmenter/geographic',{
            method: 'GET',
		});
	}
	cities_by_states(states){
		let citiesStr = Array.isArray(states) ? states.join(',') : states;
		return this.httpCall('interfaz/segmenter/cities/'+citiesStr,{
            method: 'GET',
		});
	}


}

