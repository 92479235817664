<template lang="pug">
    v-card-text()
        v-row( justify="center" align="center")
            v-col(cols='10' class="my-5" )
                v-divider
            v-col( sm="12")
                h1( class="text-center text-h6 blue-500--text") Comportamiento de compra
                p( class="text-center my-3") Elige el comportamiento más adecuado a tus necesidades.

            v-col( cols="12" class="d-flex justify-center")
                v-checkbox(
                    class="mr-2"
                    v-for="n in optionsComportamientoCompra"
                    v-model="comportamientoCompra"
                    :key="n"
                    :label="`${n}`"
                    :value="n")
                
        ComprasGenerales( v-if="comportamientoCompra === 'Compras generales'" @nextStep="onNextStep" )

        ComprasDetalle( v-if="comportamientoCompra === 'Compras a detalle'" @nextStep="onNextStep")


</template>

<script>

import ComprasGenerales from './ComprasGenerales'
import ComprasDetalle from './ComprasDetalle'


export default {
    name: 'ComportamientCompra',
    components: {
        ComprasGenerales,
        ComprasDetalle
    },
    data() {
        return {
            optionsComportamientoCompra: ['Compras generales', 'Compras a detalle'],
            comportamientoCompra: 'Compras generales'
        }
    },
    methods: {
        onNextStep(){
			this.$emit('nextStep');
        }
    }
}
</script>
