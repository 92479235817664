import BackendAuthService from "./BackendAuthService";

export default class UseCasesService extends BackendAuthService {

	constructor() {
		super();
	}

	segmenter(data){
		return this.httpCall('api/v1/usecases/segmenter',{
            method: 'POST',
            data
		});
	}

}

