/**
 * Compara dos arreglos planos, de una sola dimension, si son iguales retorna true
 * 
 * @param {array} a
 * @param {array} b
 * @return {boolean}
 */

export const arrPlainEquals = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

