<template lang="pug">
    ValidationObserver( ref="observer")
        v-card-text( class="px-3")
            v-row( justify="center" align="center")
                v-col( sm="12")
                    h1( class="text-center text-h6 blue-500--text") Modelos de segmentación de clientes
                    p( class="text-center my-3") Elige el modelo de segmentación de clientes que mas se ajuste a tus necesidades.
            
            v-row( justify="center" align="center")
                v-col( sm="1" justify="center" class="text-center")
                    v-checkbox(
                        v-model="step_data.segment"
                        value="rfm"
                        hide-details
                        class="my-0 mx-auto text-center")
                v-col(sm="11")
                    h2(class="text-subtitle-1 blue-600--text font-weight-bold") RFM por categorías
                    p(class="blue-600--text mt-2") ¿Qué hace?
                    p(class="mb-0 ") Consiste en clasificar a los  clientes Coppel digitales mediante tres variables indicadoras de los últimos 2 años.
                    p(class="mb-0 ") <span class="font-weight-bold"> Recencia: </span> Tiempo transcurrido desde su última compra.
                    p(class="mb-0 ") <span class="font-weight-bold"> Frecuencia: </span> Número  de compras.
                    p(class="mb-0 ") <span class="font-weight-bold"> Monetario: </span> Valor de las compras totales del cliente.
                    p(class="mb-0 mt-2") Además puedes combinarlas por áreas, categorías de productos y tipo de compra. Este modelo califica cada variable del<span class="font-weight-bold blue-500--text"> 1 al 4</span>, donde <span class="font-weight-bold blue-500--text"> 4 </span> es el valor más alto. 


            // fields RFM 
            v-row( justify="end" align="center")
                v-col(v-if="step_data.segment=='rfm'" sm="11")
                    v-expand-transition
                        v-row
                            v-col(sm="4")
                                p(class="text-subtitle-1 blue-600--text font-weight-bold mb-0") Área de productos
                                label(class="mt-2 text-caption") Elige una o varias opciones.
                                v-checkbox(
                                        label="Muebles"
                                        v-model="step_data.rfm.des_area"
                                        outlined
                                        value="Muebles")
                                v-checkbox(
                                        label="Ropa"
                                        v-model="step_data.rfm.des_area"
                                        outlined
                                        value="Ropa")
                            v-col(sm="4")
                                p(class="text-subtitle-1 blue-600--text font-weight-bold mb-0") Categoría de productos
                                v-select(
                                    label="Selecciona una o varias categorías."
                                    :loading="loadingInputsCategoriaSubcatDepClase"
                                    v-model="step_data.rfm.des_categoria"
                                    multiple
                                    small-chips
                                    :items="des_categoria_rfm")
                            v-col(sm="4")
                                p(class="text-subtitle-1 blue-600--text font-weight-bold mb-0") Tipo de compra
                                label(class="mt-2 text-caption")  Selecciona uno o varios tipos de compra.
                                v-checkbox(
                                        label="Contado"
                                        v-model="step_data.rfm.des_tipocompra"
                                        outlined
                                        value="Contado")
                                v-checkbox(
                                        label="Crédito"
                                        v-model="step_data.rfm.des_tipocompra"
                                        outlined
                                        value="Crédito coppel")
                    v-expand-transition
                        v-row
                            v-col(sm="12")
                                p(class="mb-0") De acuerdo a las categorías que seleccionaste. Elige las variables RFM más adecuadas a tu campaña.  Puedes combinar todas o solo la más relevante para tí. Recuerda que el valor 4 es el más alto.
                                p(class="mb-0") <b> Ejemplo: </b> Recencia 4 indica que un cliente compró recientemente ese tipo de categoría.  Frecuencia 4: El mayor número de veces que un cliente compra esa categoría. Monetario 4: El cliente gastó más en sus compras.        

                            v-col(sm="4")
                                p(class="text-subtitle-1 blue-600--text mb-0") R: Recencia
                                label(class="text-caption") Tiempo transcurrido desde su última compra

                                
                                v-checkbox(
                                        v-for="(item,index) of segments"
                                        :key="`recency-${index}`"
                                        :label="''+item"
                                        v-model="step_data.rfm.RecencyVAL"
                                        outlined
                                        :value="item")
                            v-col(sm="4")
                                p(class="text-subtitle-1 blue-600--text mb-0") F: Frecuencia
                                label(class="text-caption") Número  de compras.
                                v-checkbox(
                                    v-for="(item,index) of segments"
                                    :key="`frecuency-${index}`"
                                    :label="''+item"
                                    v-model="step_data.rfm.FrecuencyVAL"
                                    outlined
                                    :value="item")
                            v-col(sm="4")
                                p(class="text-subtitle-1 blue-600--text mb-0") M: Monetario
                                label(class="text-caption") Valor de las compras totales del cliente.
                                v-checkbox(
                                    v-for="(item,index) of segments"
                                    :key="`monetary-${index}`"
                                    :label="''+item"
                                    v-model="step_data.rfm.MonetaryVAL"
                                    outlined
                                    :error-messages="errors"
                                    :value="item")
                            
            v-row( justify="end" align="center")
                v-col(sm="11")
                    v-divider
            v-row( justify="center" align="center")
                v-col( sm="1")
                    v-checkbox(
                        v-model="step_data.segment"
                        value="spc"
                        hide-details
                        class="")
                v-col(sm="11")
                    h2(class="text-subtitle-1 blue-600--text font-weight-bold") SPC
                    p(class="blue-600--text mt-2") ¿Qué hace?
                    p(class="mb-0 ") Este modelo clasifica a los clientes de acuerdo al número de compras y tiempo transcurrido entre compras por medios digitales y los divide por estrategia de comunicación y probabilidad de compra.
            // fields spc v-expand-transition
            v-row( justify="end" align="center")
                v-col(v-if="step_data.segment=='spc'"  sm="11")
                    v-expand-transition
                        v-row
                            v-col(sm="12")
                                p(class="mb-0 text-center") Elige las variables del modelo SPC "Probabilidad de compra" mas adecuadas a tu campaña.
                            v-col(sm="6")
                                p(class="text-subtitle-1 blue-600--text") Retencion del cliente
                                
                                v-checkbox(
                                        v-for="(item,index) of spc_retention"
                                        :key="`retention-${index}`"
                                        :label="item.text"
                                        v-model="step_data.spc"
                                        outlined
                                        :value="item.value")
                            v-col(sm="6")
                                p(class="text-subtitle-1 blue-600--text") Recuperacion del cliente
                                v-checkbox(
                                        v-for="(item,index) of spc_recovery"
                                        :key="`recovery-${index}`"
                                        :label="item.text"
                                        v-model="step_data.spc"
                                        outlined
                                        :error-messages="errors"
                                        :value="item.value")
            
            v-row( justify="end" align="center")
                v-col(sm="11")
                    v-divider
                    
            v-row( justify="end" align="center")
                v-col( sm="1" justify="center" class="text-center")
                    v-checkbox(
                        v-model="step_data.segment"
                        value="puntuality"
                        hide-details
                        class="my-0 mx-auto text-center")
                v-col(sm="11")
                    h2(class="text-subtitle-1 blue-600--text font-weight-bold") Puntualidad "N"
                    p(class="blue-600--text mt-2") ¿Qué hace?
                    p(class="mb-0 ") Utiliza diversas variables económicas, demográficas y de perfil del cliente que permite predecir la puntualidad de abono para Clientes clasificación "N", mejor conocidos como clientes Nuevos.
                    p(class="mb-0 ") Elige la puntualidad predictiva calculada por el modelo de Puntualidad "N" más adecuadas a tu campaña.


            v-row( justify="center" align="center" v-if="step_data.segment=='puntuality'")
                v-col(sm="2" class="" v-for="(item, index) of puntuality" :key="`puntuality-${index}`")
                    ValidationProvider(
                        v-slot="{ errors }"
                        :name="`Cliente `"
                        rules="required")
                        v-checkbox(
                            :label="`Cliente ${item}`"
                            v-model="step_data.puntuality"
                            outlined
                            :error-messages="errors"
                            :value="item")
            v-divider(class="mt-10")

            v-row( justify="end" align="center" )
                v-col(sm="1" justify="center" class="text-center")
                    v-checkbox(
                        v-model="step_data.segment"
                        value="baseBuscadorApp"
                        hide-details
                        class="my-0 mx-auto text-center")
                v-col(sm="11")
                    h2(class="text-subtitle-1 blue-600--text font-weight-bold") Base Buscador App
                        v-tooltip( top class="d-inline")
                            template(v-slot:activator='{ on, attrs }')
                                v-btn(icon color='orange' v-bind='attrs' v-on='on')
                                    v-icon mdi-alert-circle
                            p( class="text-caption text-justify") 
                                | Sin soporte para envío de audiencias a Google Ads
                    p(class="blue-600--text mt-2") ¿Qué hace?
                    p(class="mb-0 ") Identifica a los clientes que hicieron alguna búsqueda de un producto, marca o característica de un artículo dentro de la App Coppel. Permite filtrar por varios tipos de clasificación y organización de productos Coppel. Utiliza los últimos 3 meses de búsquedas pues es el tiempo límite que un cliente puede continuar interesado en la búsqueda y adquisición de su producto. 
                    p(class="mb-0 ") Elige los filtros del Buscador de la App Coppel  más adecuados  para tu campaña.
            
            v-row( justify="end" align="center" v-if="step_data.segment=='baseBuscadorApp'")
                v-col( sm="11")
                    v-row
                        v-col(cols='12' md='4')
                            p( class="text-title font-weight-bold blue-500--text mt-5") Área productos
                            v-select( 
                                    :items="areaItems" 
                                    label="Selecciona una o varias opciones"
                                    v-model='step_data.baseBuscadorApp.des_Area'
                                    multiple chips)

                        v-col(cols='12' md='4') 
                            p( class="text-title font-weight-bold blue-500--text mt-5") Categoría de productos
                            v-select( 
                                    :items="categoryItems"
                                    :loading="loadingInputsCategoriaSubcatDepClase"
                                    label="Selecciona una o varias opciones"
                                    v-model='step_data.baseBuscadorApp.des_Categoria'
                                    multiple chips)

                        v-col(cols='12' md='4') 
                            p( class="text-title font-weight-bold blue-500--text mt-5") Subcategoría de productos
                            v-select( 
                                    :disabled="step_data.baseBuscadorApp.des_Categoria.length == 0"
                                    :items="subcategoryItems"
                                    :loading="loadingInputsCategoriaSubcatDepClase"
                                    label="Selecciona una o varias opciones"
                                    v-model='step_data.baseBuscadorApp.des_Subcategoria'
                                    multiple chips)
                    v-row
                        v-col(cols='12' md='4') 
                            p( class="text-title font-weight-bold blue-500--text mt-5") Departamento de productos
                            v-select( 
                                    :items="depProductsItems" 
                                    :loading="loadingInputsCategoriaSubcatDepClase"
                                    label="Selecciona una o varias opciones"
                                    v-model='step_data.baseBuscadorApp.des_Departamento'
                                    @blur="onBlurSelectDep"
                                    multiple chips) 

                        v-col(cols='12' md='4') 
                            p( class="text-title font-weight-bold blue-500--text mt-5") Clase
                            label(class="text-caption") Busca y elige una o varias clases
                            v-autocomplete(
                                append-icon=""
                                append-outer-icon="mdi-magnify"
                                :loading="loadingInputsCategoriaSubcatDepClase"
                                v-model="step_data.baseBuscadorApp.des_Clase"
                                :items="clasesItems"
                                @blur="onBlurSelectClase"
                                chips
                                label="Busca y elige una o varias clases"
                                small-chips
                                clearable
                                multiple
                            )
                            
                        v-col(cols='12' md='4') 
                            p( class="text-title font-weight-bold blue-500--text mt-5") Familia
                            label(class="text-caption") Busca y elige una o varias familias
                            v-autocomplete(
                                :loading="loadingInputFamilia"
                                v-model="step_data.baseBuscadorApp.des_Familia"
                                :items="familyItems"
                                append-icon=""
                                append-outer-icon="mdi-magnify"
                                chips
                                label="Busca y elige una o varias familias"
                                small-chips
                                clearable
                                multiple
                            )
                    v-row
                        v-col(cols='12' md='4') 
                            p( class="text-title font-weight-bold blue-500--text mt-5") Marca
                            label(class="text-caption") Busca y elige una o varias marcas
                            v-autocomplete(
                                append-icon=""
                                append-outer-icon="mdi-magnify"
                                :loading="loadingInputMarcas"
                                v-model="step_data.baseBuscadorApp.des_Marca"
                                :items="marcasItems"
                                chips
                                label="Busca y elige una o varias marcas"
                                small-chips
                                clearable
                                multiple
                            )

                        v-col(cols='12' md='4') 
                            p( class="text-title font-weight-bold blue-500--text mt-5") Descripción de artículo
                            label(class="text-caption") Busca y elige una o varias descripciones
                            v-autocomplete(
                                append-icon=""
                                append-outer-icon="mdi-magnify"
                                :loading="loadingInputArticulosDesc"
                                v-model="step_data.baseBuscadorApp.des_Articulo"
                                :items="desArticulosItems"
                                chips
                                label="Busca y elige una o varias descripciones"
                                small-chips
                                clearable
                                multiple
                            )

                        v-col(cols='12' md='4') 
                            p( class="text-title font-weight-bold blue-500--text mt-5") Nombre del artículo
                            label(class="text-caption") Busca y elige uno o varios nombres
                            v-autocomplete(
                                append-icon=""
                                append-outer-icon="mdi-magnify"
                                :loading="loadingInputArticulos"
                                v-model="step_data.baseBuscadorApp.nom_articulo"
                                :items="nomArticulosItems"
                                chips
                                label="Busca y elige uno o varios nombres"
                                small-chips
                                clearable
                                multiple
                            )               
            v-divider(class="mt-10")


        v-card-actions
            v-spacer
            v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente

</template>

<script>

import  InputItemsService from '../../../../services/InputItemsService';
const inputItemsService = new InputItemsService();


//import {  area_dep_cat_subcat_group_array, area_category_group_array } from '../../../../data' 
export default {
	name: 'ModelosSegmentacionCliente',
	data() {
		return {
			errors: '',
			segments: [4,3,2,1], // R F M
			spc_retention : [{text:'Alta probabilidad de compra',value:'C1'},{text:'Baja probabilidad de compra',value:'C2'}],
			spc_recovery  : [{text:'Alta probabilidad de compra',value:'C3'},{text:'Baja probabilidad de compra',value:'C4'}],
			puntuality	  : ['A','B', 'C','D','Z'],
			des_categoria_rfm : [],
            // -----------------------------

            // Base búscador App
            areaItems: [
                {text:"Muebles",value:"Muebles"},
                {text:"Ropa",value:"Ropa"},
                //{text:"Sin dato",value:"unidentified"}, si funciona. en db es des_Area IS NULL
            ],
            // jalar de base de datos
            categoryItems: [],
            // jalar de base de datos
            subcategoryItems: [],
            depProductsItems: [],
            familyItems: [],
            clasesItems: [],
            marcasItems: [],
            desArticulosItems: [],
            nomArticulosItems: [],
            //loading
            //loadingInputClase: false,
			loadingInputFamilia: false,
			loadingInputMarcas: false,
			loadingInputArticulosDesc: false,
			loadingInputArticulos: false,
            loadingInputsCategoriaSubcatDepClase: false,

			step_data :{
				segment: '',
				//type_model: 'Modelos de segmentación de clientes',
                rfm: {
                    des_area: [],
                    des_categoria:[],
                    des_tipocompra: [],
                    RecencyVAL: [],
                    FrecuencyVAL: [],
                    MonetaryVAL: [],
                },
				spc: [],
				puntuality: [],

                // Búscador App
                baseBuscadorApp: {
                    des_Area: [],
                    des_Categoria: [],
                    des_Subcategoria: [],
                    des_Departamento: [],
                    des_Clase: [],
                    des_Familia: [],
                    des_Marca: [],
                    des_Articulo: [],
                    nom_articulo: []
                },
			},

			temp_data : {},


            area_dep_cat_subcat_group_array: []
			
		}
	},
    computed: {

    },
    watch: {
		"step_data.rfm.des_area": function(value) {
            // reset
            this.step_data.rfm.des_categoria = [];
            // Filter categories by area
            if(value.includes('Ropa') || value.includes('Muebles')) {
                let _des_categoria_rfm = this.area_dep_cat_subcat_group_array.filter(item =>{
                    return value.includes(item.area);
                }).map(item => item.categoria );
                this.des_categoria_rfm = this.removeDuplicates(_des_categoria_rfm);
            } else {
                this.des_categoria_rfm = this.removeDuplicates( this.area_dep_cat_subcat_group_array.map(item => item.categoria ) );
            }
		},
        // fill select Categoría de productos, Base Buscador App
        "step_data.baseBuscadorApp.des_Area": function(areas) {
            // reset
            this.step_data.baseBuscadorApp.des_Categoria = [];
            this.step_data.baseBuscadorApp.des_Departamento = [];
            // Filter categories by area
            if(areas.length > 0) {
                this.categoryItems = this.removeDuplicates(
                    this.area_dep_cat_subcat_group_array
                        .filter(item =>{
                            return areas.includes(item.area);
                        })
                        .map(item => item.categoria )
                ).sort();

                this.depProductsItems = this.removeDuplicates(
                    this.area_dep_cat_subcat_group_array
                        .filter(item => {
                            return areas.includes(item.area);
                        })
                        .map(item => item.departamento )
                ).sort()
            } else {
                this.categoryItems = this.removeDuplicates(  this.area_dep_cat_subcat_group_array.map(item => item.categoria) ).sort()
                this.depProductsItems = this.removeDuplicates(  this.area_dep_cat_subcat_group_array.map(item => item.departamento) ).sort()
            }
		},
        // fill select Subcategoría de productos, Base Buscador App
        "step_data.baseBuscadorApp.des_Categoria": function(categories) {
            // reset
            this.step_data.baseBuscadorApp.des_Subcategoria = [];
            // Filter subcategory by category
            if(categories.length > 0) {
                this.subcategoryItems = this.removeDuplicates(
                    this.area_dep_cat_subcat_group_array
                        .filter(item =>{
                            return categories.includes( item.categoria)
                        })
                        .map(item => item.subcategoria )
                ).sort()
            } else {
                this.subcategoryItems = this.removeDuplicates(  this.area_dep_cat_subcat_group_array.map(item => item.subcategoria) ).sort()
            }
		},
        "step_data.baseBuscadorApp.des_Clase": function(clases) {
            // limpiar Familia
            this.familyItems = [];
            this.step_data.baseBuscadorApp.des_Familia = [];
        }
	},
	methods: {
        
        removeDuplicates(arr) {
            return [... new Set(arr)]
        },

        init() {
			//this.loadingInputClase = true;
			this.loadingInputFamilia = true;
			this.loadingInputMarcas = true;
			this.loadingInputArticulosDesc = true;
			this.loadingInputArticulos = true;
            this.loadingInputsCategoriaSubcatDepClase = true;

            // inputs departamento, categoria, subcategoria
            inputItemsService.fetchAreaDepaCatSubcatClaseJson()
            .then(response => {
                this.area_dep_cat_subcat_group_array = response.data.data;
				this.clasesItems = this.removeDuplicates(  this.area_dep_cat_subcat_group_array.map(item => item.clase) ).sort()
                this.categoryItems = this.removeDuplicates(  this.area_dep_cat_subcat_group_array.map(item => item.categoria) ).sort()
                this.subcategoryItems = this.removeDuplicates(  this.area_dep_cat_subcat_group_array.map(item => item.subcategoria) ).sort()
                this.depProductsItems = this.removeDuplicates(  this.area_dep_cat_subcat_group_array.map(item => item.departamento) ).sort()
                this.des_categoria_rfm = this.categoryItems;
			})
			.catch(errors => {
				console.log(errors)
			})
			.finally(()=>{
                this.loadingInputsCategoriaSubcatDepClase = false;
			})

            /*
			inputItemsService.jsonArticulosClase()
			.then(response => {
				this.clasesItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.finally(()=>{
				this.loadingInputClase = false;
			})*/

            inputItemsService.jsonArticulosFamilia()
			.then(response => {
				this.familyItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.finally(()=>{
                this.loadingInputFamilia = false;
			})

            inputItemsService.jsonArticulosMarca()
			.then(response => {
				this.marcasItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.finally(()=>{
                this.loadingInputMarcas = false;
			})

            inputItemsService.jsonArticulosDescripcion()
			.then(response => {
				this.desArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.finally(()=>{
                this.loadingInputArticulosDesc = false;
			})

            inputItemsService.jsonArticulosNombre()
			.then(response => {
				this.nomArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.finally(()=>{
                this.loadingInputArticulos = false;
			})
		},
        onBlurSelectDep() {
            //this.loadingInputClase = true;
            this.clasesItems = [];
            this.step_data.baseBuscadorApp.des_Clase = [];
            const departaments = this.step_data.baseBuscadorApp.des_Departamento;
            this.clasesItems = this.removeDuplicates(  
                this.area_dep_cat_subcat_group_array
                    .filter(item => departaments.includes(item.departamento)) 
                    .map(item => item.clase)
            ).sort();
            /*
            useCasesService.jsonArticulosClase(departaments)
            .then(response => {
                this.clasesItems = response.data.data;
            })
            .catch(errors => {
                console.log(errors)
            })
            .finally(()=>{
                this.loadingInputClase = false;
            })*/

        
        },
        onBlurSelectClase() {
            this.loadingInputFamilia = true;
            this.familyItems = [];
            this.step_data.baseBuscadorApp.des_Familia = [];
            let clases = this.step_data.baseBuscadorApp.des_Clase;
            inputItemsService.jsonArticulosFamilia(clases)
			.then(response => {
				this.familyItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.finally(()=>{
                this.loadingInputFamilia = false;
			})
        
        },
		handleNextStep:  function() {
			this.$refs.observer.validate().then( async res=> {
				//- if the form is valid
                
				if(res){
					this.$store.dispatch('update_step_state', { step1: this.hasStepSomeData() })
					await this.filter_form_fields()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('nextStep');
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
		filter_form_fields:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				
				const segment = this.step_data.segment
				if( segment != "")
					this.temp_data.segment = segment;
				//if(this.step_data.type_model!="")
				//	this.temp_data.type_model = this.step_data.type_model;
				//- rfm | baseBuscadorApp
				if(segment=="rfm" || segment=="baseBuscadorApp"){
                    var fields_obj = this.step_data[ segment ];
					for(const field in fields_obj) {
						const val = fields_obj[field];
						if(Array.isArray(val) && val.length>0){
							this.temp_data[field] = val;
						}
					}
				}
				else if(this.step_data["segment"]=="spc"){
					const val = this.step_data.spc;
					if(Array.isArray(val) && val.length>0)
						this.temp_data["spc"] = val;
				}
				else if(this.step_data["segment"]=="puntuality"){
					const val = this.step_data.puntuality;
					if(Array.isArray(val) && val.length>0)
						this.temp_data["puntuality"] = val;
				}

				resolve();
			})
		},

		// 
		hasStepSomeData() {

            const segment = this.step_data.segment
            var flag = false;
            if(segment=="rfm" || segment=="baseBuscadorApp"){
                
                var fields_obj = this.step_data[ segment ];
                for(const field in fields_obj) {
                    const val = fields_obj[field];
                    if(Array.isArray(val) && val.length>0){
                        flag = true;
                        break;
                    }
                }
                return flag;
            }

			if( this.step_data.segment == 'spc' && this.step_data.spc.length > 0  ) {
				return true;
			}

			if( this.step_data.segment == 'puntuality' && this.step_data.puntuality.length > 0 ) {
				return true;
			}

			return false;

		}
	},
    created() {
		this.init();
	},
    beforeDestroy() {
        this.area_dep_cat_subcat_group_array = [];
        this.desArticulosItems = [];
        this.familyItems = [];
        this.marcasItems = [];
        this.nomArticulosItems = [];
    }
}
</script>
