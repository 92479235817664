<template lang="pug">
	ValidationObserver(ref='observer')
		v-card-text()
			v-row( justify="center" align="center")
				v-col(cols='10' class="my-5" )
					v-divider
				v-col( sm="12")
					h1( class="text-center text-h6 blue-500--text") Comportamiento de crédito
					//-p( class="text-center my-3") Elige el modelo de segmentación de clientes que mas se ajuste a tus necesidades.
				
			v-row
				v-col(cols='12' md='4')
					//- ValidationProvider(v-slot='{ errors }' name='' rules='required')
					p( class="text-title font-weight-bold blue-500--text mt-5") Limite de crédito
					label(class="text-caption") Selecciona el intervalo de crédito del cliente (MXN)
					p(class="text-caption text-end") Rango: {{step_data.linea_credito[0]}} - {{step_data.linea_credito[1]}}
					v-range-slider(
						step='1'
						min='0' max='630000'
						v-model="step_data.linea_credito"
						class="mt-2")

				v-col(cols='12' md='4')
					p( class="text-title font-weight-bold blue-500--text mt-5") Crédito disponible
					label(class="text-caption") Selecciona el intervalo de crédito disponible (MXN)
					p(class="text-caption text-end") Rango: {{step_data.credito_disponible[0]}} - {{step_data.credito_disponible[1]}}
					v-range-slider(
							step='1'
							min='-2' max='684868'
							v-model="step_data.credito_disponible"
							class="mt-2")

			//-  
			v-row(justify="center")
				v-col(cols='10' )
					v-divider(class="my-5")
					p( class="text-h6 font-weight-bold blue-500--text mt-5 text-center") Saldos
			v-row

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Saldo en Ropa
						label(class="text-caption") Selecciona el intervalo de saldo pendiente del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.saldo_ropa[0]}} - {{step_data.saldo_ropa[1]}}
						v-range-slider(
								step='1'
								min='-1000' max='71825'
								v-model="step_data.saldo_ropa"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Saldo en Muebles
						label(class="text-caption") Selecciona el intervalo de saldo pendiente del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.saldo_mueble[0]}} - {{step_data.saldo_mueble[1]}}
						v-range-slider(
								step='1'
								min='-1000' max='5440012'
								v-model="step_data.saldo_mueble"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Saldo en Tiempo Aire
						label(class="text-caption") Selecciona el intervalo de saldo pendiente del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.saldo_tiempoaire[0]}} - {{step_data.saldo_tiempoaire[1]}}
						v-range-slider(
								step='1'
								min='-1000' max='12966'
								v-model="step_data.saldo_tiempoaire"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Saldo en Préstamo
						label(class="text-caption") Selecciona el intervalo de saldo pendiente del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.saldo_prestamo[0]}} - {{step_data.saldo_prestamo[1]}}
						v-range-slider(
								step='1'
								min='0' max='301363'
								v-model="step_data.saldo_prestamo"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Saldo en Revolvente
						label(class="text-caption") Selecciona el intervalo de saldo pendiente del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.saldo_revolven[0]}} - {{step_data.saldo_revolven[1]}}
						v-range-slider(
								step='1'
								min='0' max='14228'
								v-model="step_data.saldo_revolven"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Saldo en Reestructura
						label(class="text-caption") Selecciona el intervalo de saldo pendiente del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.saldo_restructura[0]}} - {{step_data.saldo_restructura[1]}}
						v-range-slider(
								step='1'
								min='0' max='5440012'
								v-model="step_data.saldo_restructura"
								class="mt-2")
			//-  
			v-row(justify="center")
				v-col(cols='10' )
					v-divider(class="my-5")
					p( class="text-h6 font-weight-bold blue-500--text mt-5 text-center") Vencidos
			v-row

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Monto vencido en ropa
						label(class="text-caption") Selecciona el intervalo de monto vencido del crédito del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.vencido_ropa[0]}} - {{step_data.vencido_ropa[1]}}
						v-range-slider(
								step='1'
								min='0' max='68635'
								v-model="step_data.vencido_ropa"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Monto vencido en muebles
						label(class="text-caption") Selecciona el intervalo de monto vencido del crédito del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.vencido_mueble[0]}} - {{step_data.vencido_mueble[1]}}
						v-range-slider(
								step='1'
								min='0' max='7769226'
								v-model="step_data.vencido_mueble"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Monto vencido en Tiempo Aire
						label(class="text-caption") Selecciona el intervalo de monto vencido del crédito del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.vencido_tiempoaire[0]}} - {{step_data.vencido_tiempoaire[1]}}
						v-range-slider(
								step='1'
								min='0' max='12966'
								v-model="step_data.vencido_tiempoaire"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Monto vencido en Préstamo
						label(class="text-caption") Selecciona el intervalo de monto vencido del crédito del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.vencido_prestamo[0]}} - {{step_data.vencido_prestamo[1]}}
						v-range-slider(
								step='1'
								min='0' max='262991'
								v-model="step_data.vencido_prestamo"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Monto vencido en Revolvente
						label(class="text-caption") Selecciona el intervalo de monto vencido del crédito del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.vencido_revolven[0]}} - {{step_data.vencido_revolven[1]}}
						v-range-slider(
								step='1'
								min='0' max='9306'
								v-model="step_data.vencido_revolven"
								class="mt-2")

				v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Monto vencido en Reestructura
						label(class="text-caption") Selecciona el intervalo de monto vencido del crédito del cliente (MXN)
						p(class="text-caption text-end") Rango: {{step_data.vencido_restructura[0]}} - {{step_data.vencido_restructura[1]}}
						v-range-slider(
								step='1'
								min='0' max='7769226'
								v-model="step_data.vencido_restructura"
								class="mt-2")


		v-card-actions
			v-spacer
			v-btn( color="primary" @click="handleNextStep"  tile elevation="0") Siguiente
</template>


<script>
import { arrPlainEquals } from '../../../../utils/helpers'

export default {
    name: 'ComportamientoCredito',
    data() {
        return {
            step_data :{
                segment: 'comportamientocredito',
                linea_credito: [0,0],
                credito_disponible: [0,0],
                saldo_ropa: [0,0],
                saldo_mueble: [0,0],
                saldo_tiempoaire: [0,0],
                saldo_prestamo: [0,0],
                saldo_revolven: [0,0],
                saldo_restructura: [0,0],
                vencido_ropa: [0,0],
                vencido_mueble: [0,0],
                vencido_tiempoaire: [0,0],
                vencido_prestamo: [0,0],
                vencido_revolven: [0,0],
                vencido_restructura: [0,0],
            },
            temp_data : {}
        }
    },
	methods: {
		handleNextStep:  function() {
			this.$refs.observer.validate().then( async res=> {
				if(res){
					this.$store.dispatch('update_step_state', { step1: this.hasStepSomeData() })
					await this.process_fields()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('nextStep');
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
        process_fields:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				this.temp_data.segment = this.step_data.segment;
				for(const field in this.step_data){
                    const val = this.step_data[field];
                    if(Array.isArray(val) && val.length>0 && !arrPlainEquals( val, [0,0] )){
                        this.temp_data[field] = val;
                    }
                }
				resolve();
			})
		},
        hasStepSomeData() {
            var flag = false;
            for(const field in this.step_data){
                const itemField = this.step_data[field];
                if(Array.isArray(itemField) && itemField.length > 0 && !arrPlainEquals( itemField, [0,0] ) ){
                    flag = true;
                    break;
                }
            }
            return flag;
		},
	}
    
}
</script>