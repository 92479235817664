<template lang="pug">
	v-card(outline='' tile='')
		v-card-text
			h2.text-h5.font-weight-bold.blue-500--text.text-center  Formulario 
			p.text-center.mb-0.mt-2 Tu archivo esta listo
			p.text-center.mb-3 Pero antes, apoyanos completando la siguiente información.
			ValidationObserver(ref='observer')
				v-row
					v-col(cols='12' md='4')
						v-subheader Tu nombre
					v-col(cols='12' md='8')
						ValidationProvider(v-slot='{ errors }' name='nombre' rules='required')
							v-text-field(label='' v-model='step_data.name' :error-messages='errors' value='nombre')
				v-row
					v-col(cols='12' md='4')
						v-subheader Área a la que perteneces
					v-col(cols='12' md='8')
						ValidationProvider(v-slot='{ errors }' name='Área a la que perteneces' rules='required')
							v-text-field(label='' v-model='step_data.area' :error-messages='errors' value='Área a la que perteneces')
				v-row
					v-col(cols='12' md='4')
						v-subheader ¿Para que utilizaras tu segmento o recomendación?
					v-col(cols='12' md='8')
						ValidationProvider(v-slot='{ errors }' name='¿Para que utilizaras tu segmento o recomendación?' rules='required')
							v-text-field(label='' v-model='step_data.campaign.target' :error-messages='errors' value='¿Para que utilizaras tu segmento o recomendación?')
				v-row
					v-col(cols='12' md='4')
						v-subheader ¿En que canal utilizaras el segmento o recomendación?
					v-col(cols='12' md='8' class="pl-10")
						ValidationProvider(v-slot='{ errors }'  name='canal' :rules="{'required': !step_data.campaign.other_channel_enabled}" )
							v-checkbox(
								class="mt-0"
								v-for='(n,i) in radioOptions'
								:key='i'
								:label='`${n}`'
								v-model="step_data.campaign.channels"
								:value='n')
							div(cols='12' md='12' class="text-left py-0 my-0" v-if="errors") 
								span(v-for="error of errors " class="red--text") {{error}}
						v-checkbox(
							v-model="step_data.campaign.other_channel_enabled"
							hide-details
							class="shrink mr-2 mt-0 d-inline-block")
						ValidationProvider(v-slot='{ errors }' name='Otro' :rules="{'required': step_data.campaign.other_channel_enabled}" )
							v-text-field(
								:disabled="!step_data.campaign.other_channel_enabled"
								v-model='step_data.campaign.other_channel'
								:error-messages='errors'
								class="d-inline-block"
								label="Otro")
				v-row
					v-col(cols='12' md='4')
						v-subheader ¿Podrias compartirnos el nombre de tu campaña o proyecto?
					v-col(cols='12' md='8')
						ValidationProvider(
							v-slot='{ errors }' 
							name='nombre de tu campaña o proyecto' rules='required')
							v-text-field(label='' v-model='step_data.campaign.name'  :error-messages='errors' value='nombre de tu campaña o proyecto')
				v-row
					v-col( class="text-left" cols='12' md='4')
						v-subheader ¿Cual es la fecha de inicio de tu campaña o proyecto?. Si aún no la conoces puedes poner una fecha tentativa.
					v-col(cols='12' md='8')
						v-dialog(
							ref="dialog"
							v-model="modal"
							:return-value.sync="step_data.campaign.start"
							persistent
							width="290px")
							template( v-slot:activator="{ on, attrs }")
								ValidationProvider(
									v-slot='{ errors }' 
									name='Fecha de inicio' rules='required')
									v-text-field(
										v-model="step_data.campaign.start"
										label="Fecha de inicio"
										:error-messages='errors'
										prepend-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									)
							v-date-picker(
								v-model="date"
								range
								@change="inicioDatePicker"
								:min="min_date"
								scrollable)
								v-spacer
								v-btn(
									text
									color="primary primary--text"
									@click="modal = false") Cancelar
								v-btn(
									text
									color="primary"
									@click="$refs.dialog.save(date)") OK
				
				
				v-row( v-if="$checkPermission(['send_customer_match_to_google_ads', 'send_customer_match_to_facebook']) && isGoogleAdsSupported" )
					v-col(cols='12' md='4')
						v-subheader Seleccione el destino de la segmentación
					v-col(cols='12' md='8' class="pl-10")
						ValidationProvider(v-slot='{ errors }'  name='destino de la segmentación' rules="required" )
							v-radio-group( v-model="step_data.customerMatch.destination" mandatory)
								v-radio(
									label='CSV'
									value='CSV')
								v-radio(
									class="mt-2"
									v-if="$checkPermission('send_customer_match_to_google_ads')"
									label='Google Ads'
									value='GOOGLE_ADS')
								v-radio(
									class="mt-2"
									v-if="$checkPermission('send_customer_match_to_facebook')"
									label='Facebook'
									value='FACEBOOK')
								div(cols='12' md='12' class="text-left py-0 my-0" v-if="errors") 
									span(v-for="error of errors " class="red--text") {{error}}
									//-span(class="red--text") Selecciona una casilla para continuar

				v-row(v-if="is_GOOGLE_ADS_or_FACEBOOK_choosen && isGoogleAdsSupported")
					v-col(cols='12' md='4' class="text-left")
						v-subheader ¿Desea automatizar esta audiencia?
					v-col(cols='12' md='8' class="pl-10")
						ValidationProvider(v-slot='{ errors }'  name='automatizar audiencia' rules="required" )
							v-radio-group( v-model="step_data.customerMatch.enableAutomation" mandatory row)
								v-radio(
									label="No" 
									:value="false")
								v-radio(
									label="Si"
									:value="true")
							div(cols='12' md='12' class="text-left py-0 my-0" v-if="errors") 
								span(v-for="error of errors " class="red--text") {{error}}

				v-row( v-if="step_data.customerMatch.enableAutomation" )
					v-col(cols='12' md='4')
						v-subheader Frecuencia de la automatización
					v-col(cols='12' md='8' class="pl-10")
						ValidationProvider(v-slot='{ errors }'  name='frecuencia de la automatización' rules="required" )
							v-select( 
								:items="frequencyValues" 
								v-model="step_data.customerMatch.frequency"
								:error-messages='errors'
								label="Seleciona una opción" chips)

				v-row( v-if="step_data.customerMatch.enableAutomation" )
					v-col(cols='12' md='4')
						v-subheader Fechas para automatización
						
					v-col(cols='12' md='4')
						v-dialog(
							ref="dialoStartDateAutSegmenter"
							v-model="modalStartDateAutSegmenter"
							:return-value.sync="step_data.customerMatch.startDate"
							persistent
							width="290px")
							template(v-slot:activator="{ on, attrs }")
								v-text-field(
									v-model="step_data.customerMatch.startDate"
									label="Fecha inicio"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on")
							v-date-picker(v-model="step_data.customerMatch.startDate" scrollable :min="min_date")
								v-spacer
								v-btn(text color="primary" @click="modalStartDateAutSegmenter = false") Cancel
								v-btn( text color="primary" @click="$refs.dialoStartDateAutSegmenter.save(step_data.customerMatch.startDate)") OK
					v-col(cols='12' md='4')
						v-dialog(
							ref="dialoEndDateAutSegmenter"
							v-model="modalEndDateAutSegmenter"
							:return-value.sync="step_data.customerMatch.endDate"
							persistent
							width="290px")
							template(v-slot:activator="{ on, attrs }")
								v-text-field(
									v-model="step_data.customerMatch.endDate"
									label="Fecha final"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on")
							v-date-picker(v-model="step_data.customerMatch.endDate" scrollable :min="minEndDateAutCustomerMatch" :max="maxEndDateAutCustomerMatch")
								v-spacer
								v-btn(text color="primary" @click="modalEndDateAutSegmenter = false") Cancel
								v-btn( text color="primary" @click="$refs.dialoEndDateAutSegmenter.save(step_data.customerMatch.endDate)") OK

				v-row( v-if="is_GOOGLE_ADS_choosen && isGoogleAdsSupported" )
					v-col(cols='12' md='4')
						v-subheader Selecciona una cuenta de Google Ads
					v-col(cols='12' md='8' class="pl-10")
						ValidationProvider(v-slot='{ errors }'  name='MCC' rules="required" )
							v-select( 
								:items="MCC" 
								v-model="step_data.customerMatch.mcc_account"
								:error-messages='errors'
								label="Seleciona una opción" chips)

				v-row(v-if="is_CSV_choosen")
					v-col(cols='12' md='4')
						v-subheader ¿Cómo quieres nombrar tu archivo?
					v-col(cols='12' md='8')
						ValidationProvider(v-slot='{ errors }' name='archivo' rules='required')
							v-text-field(label='' v-model='step_data.filename'  :error-messages='errors' value='archivo')
				
				v-row(v-if="is_GOOGLE_ADS_or_FACEBOOK_choosen && isGoogleAdsSupported")
					v-col(cols='12' md='4')
						v-subheader ¿Cómo quieres nombrar a tu audiencia? 
					v-col(cols='12' md='8')
						ValidationProvider(v-slot='{ errors }' name='archivo' rules="required|alphabetNumersUnderScore")
							v-text-field(label='' v-model='step_data.customerMatch.audienceName'  :error-messages='errors' value='archivo')

				v-row
					v-col(v-if='show_alert')
						v-expand-transition
							v-alert(type='success')  Procesando... recibirás un correo electrónico con la segmentación. 
								v-btn(@click="reloadPage" color='blue-700' small) Crear otra segmentación
		v-card-actions.px-0.text-end(justify='end')
			v-btn(color='grey' dark  tile elevation @click='prevStep')  Anterior 
			v-spacer
			v-btn( 
				color='primary' 
				class="text-none" 
				tile 
				elevation 
				@click='save' 
				:loading="btn_save_loading" 
				:disabled="btn_save_loading || show_alert" )  ¡Listo! Enviar datos
</template>
<script>
//import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import moment from 'moment'
import {mapGetters} from 'vuex'
export default {
	name: 'FormularioComponent',
	props: {
		show_alert:{
			type: Boolean
		},
		btn_save_loading: {
			type: Boolean
		}
	},
	data() {
		return {
			//enabled: false,
			//- date: '',
			min_date: new Date().toISOString().substr(0, 10),
			modal: false,

			radioOptions: ['Email', 'SMS', 'Paid Media', 'Venta por Teléfono'],
			frequencyValues: [
				{text: 'Semanal', value: 'weekly'},
				{text: 'Quincenal', value: 'fortnightly'},
				{text: 'Mensual', value: 'monthly'}],
			// cuantas google ads
			MCC: [
				//{ text: "Afore Coppel", value: "315-579-2729" },
				//{ text: "Argentina Venta", value: "642-333-8311" },
				{ text: "BanCoppel DUD", value: "360-730-9800" },
				//{ text: "Blog Coppel MKT", value: "413-030-8654" },
				//{ text: "Club de protección", value: "478-111-8978" },
				//{ text: "Club de protección Branding", value: "440-929-0344" },
				//{ text: "Coppel Abonos", value: "791-544-6639" },
				//{ text: "Coppel App", value: "874-470-0195" },
				//{ text: "Coppel App Engagement Ominacanal", value: "583-039-1625" },
				//{ text: "Coppel Argentina", value: "758-921-6896" },
				//{ text: "Coppel Coop HP", value: "636-548-9801" },
				//{ text: "Coppel Max", value: "862-928-1207" },
				//{ text: "Coppel Pay", value: "295-227-6098" },
				//{ text: "Coppel Personalización Omnicanal", value: "312-343-0799" },
				//{ text: "Coppel Préstamos", value: "862-354-0837" },
				//{ text: "Coppel Redi", value: "644-377-1099" },
				//{ text: "Coppel Shopping Omnicanal", value: "724-518-3205" },
				//{ text: "Coppel Tienda Display", value: "476-647-1111" },
				//{ text: "Coppel Tienda Shopping", value: "101-942-3192" },
				//{ text: "Coppel Travel", value: "128-547-1444" },
				//{ text: "Coppel_Branding_MarcasExclusivas_Otros", value: "331-375-1268" },
				//{ text: "Coppel_Branding_México", value: "956-204-9722" },
				//{ text: "Corporate Deal", value: "482-166-8807" },
				//{ text: "Crédito Coppel", value: "580-041-2092" },
				//{ text: "Crédito SA360", value: "572-028-6539" },
				//{ text: "Especiales Fintech", value: "450-099-5807" },
				//{ text: "Fashion Market", value: "893-975-6603" },
				//{ text: "Marketplace Omnicanal", value: "724-520-9694" },
				//{ text: "Merca Directa", value: "884-728-30542" },
				//{ text: "Neo*City", value: "147-372-6311" },
				//{ text: "Revista (catalogo) Branding", value: "366-092-2478" },
				//{ text: "Sportline", value: "723-462-4550" },
				//{ text: "Tienda Branding Omnicanal", value: "555-658-4866" },
				//{ text: "Tienda Coppel", value: "253-517-9120" },
				//{ text: "Tienda Coppel SA360", value: "402-591-9415" },
				//{ text: "Zuum", value: "836-786-6007" },
				{ text: "Crédito Coppel DUD", value: "578-902-3801" }
				
			],
			date: [],
			formatedDate: null,
			menu: false,

			//datepickers
			modalStartDateAutSegmenter: false,
			modalEndDateAutSegmenter: false,
			minEndDateAutCustomerMatch: new Date().toISOString().substr(0, 10), // min date datepicker
			maxEndDateAutCustomerMatch: undefined, // max date datepicker
			
			step_data :{
				customerMatch: {
					destination: 'CSV',
					enableAutomation: false,
					audienceName: '',
					frequency: '',
					mcc_account: '',
					startDate: new Date().toISOString().substr(0, 10),
					endDate: new Date().toISOString().substr(0, 10),
				},
				name: '',
				area: '',
				campaign:{
					target: '',
					channels: [],
					other_channel_enabled: false,
					other_channel: '',
					name: '',
					start: [],
				},
				email: '',
				filename: '',
			},
			temp_data : {},
		}
	},
	computed: {
		is_CSV_choosen: function () {
			return this.step_data.customerMatch.destination.includes('CSV');
		},
		is_GOOGLE_ADS_or_FACEBOOK_choosen: function () {
			//return this.step_data.customerMatch.destination.some((item) => ['GOOGLE_ADS', 'FACEBOOK'].includes(item) );
			return this.step_data.customerMatch.destination === 'GOOGLE_ADS' || this.step_data.customerMatch.destination === 'FACEBOOK';
		},
		is_GOOGLE_ADS_choosen: function () {
			return this.step_data.customerMatch.destination === 'GOOGLE_ADS';
		},
		...mapGetters(['isGoogleAdsSupported'])
	},
	watch: {
		menu (val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
		},
		date (val) {
			this.formatedDate = moment(val).format('DD/MM/YYYY')
		},
		'step_data.customerMatch.startDate' (val) {
			let strDate = val.split('-').join('/');
			let baseDate = new Date(strDate);
			let baseDate2 = new Date(strDate);
			baseDate2.setMonth( baseDate2.getMonth() + 6 )

			if ( this.step_data.customerMatch.frequency == 'weekly' ) {
				baseDate.setDate( baseDate.getDate() + 7);
			} else if( this.step_data.customerMatch.frequency == 'fortnightly' ) {
				baseDate.setDate( baseDate.getDate() + 15);
			} else {
				baseDate.setMonth( baseDate.getMonth() + 1);
			}
			this.minEndDateAutCustomerMatch = baseDate.toISOString().substr(0, 10);
			this.step_data.customerMatch.endDate = this.minEndDateAutCustomerMatch;
			this.maxEndDateAutCustomerMatch = baseDate2.toISOString().substr(0, 10);

		},

		'step_data.customerMatch.destination' (val) {
			this.step_data.customerMatch.enableAutomation = false;
			this.step_data.customerMatch.audienceName = '';
			this.step_data.filename = '';
		},

		'step_data.customerMatch.frequency' (val) {
			let strDate = this.step_data.customerMatch.startDate.split('-').join('/');
			let baseDate = new Date(strDate);
			let baseDate2 = new Date(strDate);
			baseDate2.setMonth( baseDate2.getMonth() + 6 )

			if ( this.step_data.customerMatch.frequency == 'weekly' ) {
				baseDate.setDate( baseDate.getDate() + 7);
			} else if( this.step_data.customerMatch.frequency == 'fortnightly' ) {
				baseDate.setDate( baseDate.getDate() + 15);
			} else {
				baseDate.setMonth( baseDate.getMonth() + 1);
			}
			this.minEndDateAutCustomerMatch = baseDate.toISOString().substr(0, 10);	
			this.step_data.customerMatch.endDate = this.minEndDateAutCustomerMatch;
			this.maxEndDateAutCustomerMatch = baseDate2.toISOString().substr(0, 10);


		},

		'step_data.customerMatch.enableAutomation' (val) {
			this.step_data.customerMatch.frequency = 'weekly';
			this.step_data.customerMatch.startDate = new Date().toISOString().substr(0, 10),
			this.step_data.customerMatch.endDate = new Date().toISOString().substr(0, 10),
			this.minEndDateAutCustomerMatch = new Date().toISOString().substr(0, 10);
		},
	},

	methods: {

		reloadPage: function() {
			window.location.reload();
		},

		prevStep: function() {
			this.$emit('prevStep');
		},
		save() {
			if (!this.isGoogleAdsSupported) {
				this.step_data.customerMatch.destination = 'CSV'
			}
			this.$refs.observer.validate().then((res) => {
				if (res) {
					this.$store.dispatch('step4_data',this.step_data);
					this.$emit("nextStep");
				}else{
					console.log("no valid");
				}
			});
		},
		// si la fecha 1 es mayor a la fecha 2 en el rango se invierten los valores
		evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
		inicioDatePicker(range) {
			this.date = this.evalRangeDatePicker(range)
		}
	},
}
</script>