<template lang="pug">
	v-card(outline tile)

		v-row(justify="center")
			v-col( cols="12" sm="8")
				h6(class="text-body-2  text-center mt-5") Podrás realizar selecciones y divisiones de clientes Coppel* de acuerdo a su comportamiento de compra, crédito o abonos histórico basado en los datos estadísticos disponibles que permiten crear segmentos adhoc.
			

		v-row(justify="center")
			
			v-col( cols="12")
				h2( class="text-h6 text-center font-weight-bold blue-500--text mt-5") Comportamiento de clientes
			v-col( cols="12" class="d-flex justify-center")
				v-checkbox(
					class="mr-2"
					v-for="n in opciones"
					v-model="tab"
					:key="n"
					:label="`${n}`"
					:value="n")

		v-row(justify="center")
			v-col( cols="12" sm="12" class="ml-5 text-center" )
				p(class="text-body-2 text-center mt-5 mb-0")  Elige las características  que más se ajuste a tus necesidades. 
				
		ComportamientoCompra(  v-if="tab === 'Comportamiento de compra'"  @nextStep="onNextStep" )
		ComportamientoCredito( v-if="tab === 'Comportamiento de crédito'" @nextStep="onNextStep" )
		ComportamientoAbonos(  v-if="tab === 'Comportamiento de abonos'"  @nextStep="onNextStep" )

		v-card-actions( v-if="!tab" )
			v-spacer
			v-btn( color="primary" @click="onNextStep()"  tile elevation="0") Siguiente

					
		//-v-card-actions.px-4.text-end(justify='end')
			v-btn.ml-auto(color='primary' @click='onClickSaveBtn')  Siguiente
</template>

<script>

import ComportamientoCompra from './ComportamientoCompra.vue'
import ComportamientoCredito from './ComportamientoCredito'
import ComportamientoAbonos from './ComportamientoAbonos'


export default {
	name: 'ComportamientoCliente',
	components: {
		ComportamientoCompra,
		ComportamientoCredito,
		ComportamientoAbonos
	},
	data: () => ({
		tab: 'Comportamiento de compra',
		opciones: ['Comportamiento de compra', 'Comportamiento de crédito', 'Comportamiento de abonos'],

	}),
	methods:{
		onNextStep(){
			this.$emit('nextStep');
		},
	}	
}
</script>